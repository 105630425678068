/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-prototype-builtins */

import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import queryString from "query-string";
import { CloseIcon } from "../_helpers/Icons/miscellaneousIcons";
import useDialog from "../hooks/useDialog";
import Link from "next/link";
import { AppContext } from "../context/AppContext";
import HeaderIcons from "../_helpers/Icons/headerIcons";
import classNames from "classnames";
import { useCompare } from "../services/compare";
import find from "lodash/find";
import {
  getInitialLocation,
  getRakutenParams,
  getTopOffset,
  persistCurrentLocation,
} from "../_helpers/functions";
import Button from "./Button";
import { locationArr } from "../services/constants";
import { useCart } from "../services/cart";

const diamondIconStyle = "w-5 h-5";
const diamondIcons = {
  Square: <HeaderIcons.SquareOutline className={diamondIconStyle} />,
  Round: <HeaderIcons.RoundOutline className={diamondIconStyle} />,
  Oval: <HeaderIcons.OvalOutline className={diamondIconStyle} />,
  Cushion: <HeaderIcons.CushionOutline className={diamondIconStyle} />,
  "Elongated Cushion": (
    <HeaderIcons.ElongatedCushion className={diamondIconStyle} />
  ),
  Emerald: <HeaderIcons.EmeraldOutline className={diamondIconStyle} selected />,
  Asscher: <HeaderIcons.AsscherOutline className={diamondIconStyle} selected />,
  Pear: <HeaderIcons.PearOutline className={diamondIconStyle} selected />,
  "Rose-Cut": (
    <img
      src="/assets/images/icons/rose.svg"
      className={diamondIconStyle}
      alt="rose"
    />
  ),
  Marquise: (
    <HeaderIcons.MarquiseOutline className={diamondIconStyle} selected />
  ),
  Trillion: (
    <HeaderIcons.TrillionOutline className={diamondIconStyle} selected />
  ),
  "Old-European": (
    <HeaderIcons.OldEuropeanOutline className={diamondIconStyle} selected />
  ),
  "Old-Mine": (
    <img
      src="/assets/images/icons/old-mine-outline.svg"
      className={diamondIconStyle}
      alt="old-mine"
    />
  ),
  Radiant: <HeaderIcons.RadiantOutline className={diamondIconStyle} selected />,
  Princess: (
    <HeaderIcons.PrincessOutline className={diamondIconStyle} selected />
  ),
};

const Accordian = ({ title, content, getUrl, setResetSearch, setSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white">
      <div
        className="flex text-xs justify-between items-center cursor-pointer h-12"
        onClick={toggleAccordion}
      >
        <span>Shop {title}</span>
        <div className="relative w-2.5">
          <span className="absolute w-full border-theme-blue border-[0.5px] top-1/2" />
          <span
            className={classNames(
              "absolute w-full border-[0.5px] border-theme-blue transition-all duration-300 top-1/2",
              isOpen ? "rotate-180" : "rotate-90"
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          "overflow-hidden transition-all duration-300",
          isOpen ? "max-h-96" : "max-h-0"
        )}
      >
        <div
          className={classNames(
            "transition-opacity duration-300",
            isOpen ? "opacity-100" : "opacity-0"
          )}
        >
          <SubmenuJSX
            heading={title}
            subSections={content}
            getUrl={getUrl}
            isMobile={true}
            setResetSearch={setResetSearch}
            closeHandler={(bool) => setSidebar(bool)}
          />
        </div>
      </div>
    </div>
  );
};

const LocationJSX = ({
  showLocation,
  setShowLocation,
  currentLocationAddress,
  allLocations,
  setCurrentLocation,
  currentLocation,
  dialogType,
  setSidebar,
  route,
}) => {
  const [showAllLocations, setShowAllLocations] = useState(false);
  const isDiamondRoute = route.includes("diamonds");
  return (
    <div
      className={classNames(
        "p-4 fixed left-0 right-0 bg-white transition-all duration-300 rounded-t-2xl",
        showLocation ? "bottom-0" : "-bottom-full",
        showAllLocations
          ? "h-[70%]"
          : dialogType.header && !isDiamondRoute
          ? dialogType.holidayHeader
            ? "h-[265px]"
            : "h-60"
          : "h-52"
      )}
    >
      {/* header */}
      <div className="flex items-center justify-between border-b border-light-blue pb-3">
        <p className="text-light-blue text-xs flex items-center gap-x-3">
          {showAllLocations ? (
            <img
              onClick={() => setShowAllLocations(false)}
              className="h-6 rotate-180 cursor-pointer"
              src="/assets/images/icons/arrow.svg"
              alt="left-arrow"
            />
          ) : null}
          <p>Selected Store</p>
        </p>
        <span onClick={() => setShowLocation(false)}>
          <CloseIcon className={`h-3 w-3 cursor-pointer`} />
        </span>
      </div>
      {/* content */}
      {showAllLocations ? (
        <div
          className={classNames(
            "mt-4 text-xs overflow-y-scroll h-full hide-scrollbar",
            dialogType.header && !isDiamondRoute
              ? dialogType.holidayHeader
                ? "pb-[150px]"
                : "pb-32"
              : "pb-24"
          )}
        >
          {allLocations.map((location, idx) => {
            return (
              <div
                key={location.location}
                className="flex items-start gap-2 border-b border-gray-200 last:border-none my-4 cursor-pointer"
                onClick={() => {
                  setCurrentLocation(location.location);
                  persistCurrentLocation(location.location);
                }}
              >
                {/* selected dots */}
                <span className="h-[10px] w-[10px] rounded-full border border-theme-blue mt-0.5 flex items-center justify-center">
                  {currentLocation === location.location ? (
                    <div className="h-[6px] w-[6px] rounded-full bg-theme-blue"></div>
                  ) : null}
                </span>
                {/* location */}
                <div>
                  <p className="mb-5">{location.location}</p>
                  <p>{location.address}</p>
                  <p
                    className={classNames(
                      idx === allLocations.length - 1 ? "" : "mb-5"
                    )}
                  >
                    {location.city}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="my-4 flex items-start justify-between">
          {/* current location */}
          <div className="text-xs">
            {currentLocationAddress.location}
            <p className="mt-3">{currentLocationAddress.address}</p>
            <p>{currentLocationAddress.city}</p>
            <a href={`tel:${currentLocationAddress.phone}`}>
              {currentLocationAddress.phone}
            </a>
          </div>
          <p
            className="text-light-blue cursor-pointer text-xs"
            onClick={() => {
              setShowAllLocations(true);
            }}
          >
            See All Locations
          </p>
        </div>
      )}
      <div
        className={classNames(
          "fixed left-0 right-0 w-full p-4 transition-all duration-300 bg-white",
          showLocation
            ? dialogType.header && !isDiamondRoute
              ? dialogType.holidayHeader
                ? "bottom-16"
                : "bottom-10"
              : "bottom-0"
            : "-bottom-full"
        )}
      >
        <Button
          onClick={() => {
            setSidebar(false);
            setShowAllLocations(false);
            setShowLocation(false);
          }}
          variant="custom"
          className={
            "h-8 border border-theme-blue text-theme-blue hover:text-white hover:bg-light-blue hover:border-none text-xs normal-case"
          }
        >
          <Link href={`/consultation/?location=${currentLocation}`}>
            Book an Appointment
          </Link>
        </Button>
      </div>
    </div>
  );
};

const imgAspect = (type = "", heading) => {
  if (type === "desktop") {
    switch (heading) {
      case "About":
      case "Jewelry":
        return "lg:min-w-[300px] 2xl:min-w-[448px] aspect-[1.67/1]";
      case "Diamonds":
      case "Engagement":
        return "w-full h-full";
      default:
        return "max-h-[264px] min-w-[212px]";
    }
  }

  // mobile default
  switch (heading) {
    case "About":
    case "Jewelry":
      return "min-w-[246px] aspect-[1.67/1]";
    case "Diamonds":
      return "min-w-[245px] aspect-[1.25/1]";
    case "Appointments":
      return "min-w-[219px] aspect-[0.8/1]";
    default:
      return "min-w-[327px] aspect-[1.67/1]";
  }
};

const SubmenuJSX = ({
  heading,
  subSections,
  getUrl,
  isMobile = false,
  setResetSearch,
  closeHandler,
}) => {
  const getGridCol = (type) => {
    switch (type) {
      case "Engagement":
        return "grid-cols-[1fr_2fr_2fr_2.2fr]";
      case "Diamonds":
        return "grid-cols-4";
      case "Jewelry":
        return "grid-cols-3";
      case "Appointments":
        return "grid-cols-[1fr_auto]";
      case "About":
        return "grid-cols-1";
    }
  };

  const getDefaultCSS = (heading, subSection) => {
    switch (heading) {
      case "Appointments":
        return subSection === 2
          ? "flex h-full gap-4"
          : "flex flex-col gap-[26px]";
      case "About":
        return "flex h-full gap-6";
      default:
        return `flex ${subSection === 1 ? "flex-col gap-[26px] " : "h-full"}`;
    }
  };

  const getSubsectionCss = (type, heading, subSection) => {
    switch (type.toLowerCase().split(" ")[1]) {
      case "shape":
        return "grid grid-cols-2 gap-x-6 gap-y-3";
      case "style":
        return "grid grid-cols-2 gap-x-6 gap-y-[18px]";
      case "type":
        return "flex flex-col gap-3";
      default:
        return getDefaultCSS(heading, subSection);
    }
  };

  const closeCurrentSubsection = (viewAll = false) => {
    if (heading === "Diamonds" && viewAll) {
      setResetSearch(true);
    }
    closeHandler(isMobile ? false : null);
  };

  return (
    <div
      className={classNames(
        getGridCol(heading),
        "w-full max-h-max text-xs grid gap-[25px] bg-white",
        isMobile ? "" : "p-6"
      )}
    >
      {subSections.map((section, idx) => (
        <div key={idx} className="min-h-full ">
          {/* heading */}
          {section.viewAll ? (
            <div
              className={classNames(
                "font-sans",
                isMobile
                  ? "py-3"
                  : "border-b border-light-blue pb-3 flex justify-between"
              )}
            >
              {!isMobile ? <p>Shop {section.subHeading}</p> : null}
              <Link
                href={getUrl(section.viewAll)}
                className="text-light-blue hover:text-theme-blue hover:underline transition-all duration-300 ease-in-out"
                onClick={() => closeCurrentSubsection(true)}
              >
                <span>View All</span>
              </Link>
            </div>
          ) : null}
          {/* inner content */}
          <div
            className={classNames(
              "font-serif",
              getSubsectionCss(section.subHeading, heading, section.subSection),
              section.subHeading ? "py-4" : ""
            )}
          >
            {section.links.map((link, linkIndex) => {
              return (
                <Link
                  className={classNames(
                    "text-xs relative h-full",
                    link.overlay ? "w-full" : "w-fit"
                  )}
                  key={linkIndex}
                  href={getUrl(link.link)}
                  target={link.target || "_self"}
                  onClick={() => closeCurrentSubsection()}
                >
                  <span
                    className={classNames(
                      "flex items-center gap-3",
                      link.overlay ? "text-white absolute bottom-4 left-4" : ""
                    )}
                  >
                    {section.subHeading === "by Shape" ? (
                      <span>{diamondIcons?.[link?.title]}</span>
                    ) : null}
                    {link.title}
                  </span>
                  {link.overlay ? (
                    <img
                      src={link.overlay}
                      className={classNames(
                        "object-cover",
                        imgAspect("desktop", heading)
                      )}
                      alt="overlay"
                    />
                  ) : null}
                </Link>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const MobileNav = ({
  showSidebar,
  setSidebar,
  navData,
  getUrl,
  currentLocation,
  setCurrentLocation,
  currentLocationAddress,
  allLocations,
  dialogType,
  setResetSearch,
  route,
}) => {
  const [showSubsection, setShowSubsection] = useState({
    id: null,
    title: null,
    data: null,
  });
  const [showLocation, setShowLocation] = useState(false);

  return (
    <div
      className={classNames(
        " fixed left-0 h-full w-full bg-white sm:w-vw-2/3 transition-transform duration-300 flex flex-col z-20 overflow-hidden lg:hidden",
        getTopOffset(route, dialogType, navData),
        showSidebar
          ? "translate-x-0 sm:border-r border-theme-blue"
          : "-translate-x-full"
      )}
    >
      <div className="w-full border-theme-blue border-b p-4 flex items-center">
        <span onClick={() => setSidebar(false)} className="cursor-pointer">
          <CloseIcon
            id="header_menu_close_icon"
            className="h-6 w-5 text-theme-blue"
          />
        </span>
        <Link
          href={`/`}
          className="absolute left-1/2 transform -translate-x-1/2"
        >
          <img
            src={`/assets/images/header/mobile-logo.svg`}
            className="h-8"
            alt="Frank Darling"
          />
        </Link>
      </div>
      <div className="p-4 flex flex-col gap-7">
        {navData.map((pill, idx) =>
          pill.subSections ? (
            <p
              className="text-xs cursor-pointer"
              key={idx}
              onClick={() =>
                setShowSubsection({
                  id: idx,
                  title: pill.heading,
                  data: pill.subSections,
                })
              }
            >
              {pill.heading}
            </p>
          ) : (
            <Link
              href={getUrl(pill.link)}
              onClick={() => setSidebar(false)}
              className={classNames(
                "text-xs",
                pill.mobileOnly ? "border-t border-theme-blue pt-3" : ""
              )}
            >
              <div
                className={classNames(
                  pill.mobileOnly ? "flex items-center gap-3" : ""
                )}
              >
                {pill.mobileOnly ? (
                  <HeaderIcons.CompareIcon className="h-5 w-5 text-theme-blue" />
                ) : null}
                {pill.heading}
              </div>
            </Link>
          )
        )}
        {/* mobile location bar */}

        <div className="flex items-center text-xs">
          <div className="flex items-center gap-3 ">
            <HeaderIcons.LocationIcon className="h-5 w-5" />
            Your Store:
          </div>
          <span
            className="ml-2 underline cursor-pointer"
            onClick={() => setShowLocation(true)}
          >
            {currentLocation}
          </span>
          <img
            className={classNames(
              "h-2.5 mt-1 ml-2 transition-all duration-300 cursor-pointer",
              showLocation ? "rotate-180" : "rotate-90"
            )}
            onClick={() => setShowLocation(true)}
            src="/assets/images/icons/chevron.svg"
            alt="chevron"
          />
        </div>
        {showLocation ? (
          // location overlay
          <div
            onClick={() => {
              setShowLocation(false);
            }}
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
          ></div>
        ) : null}
        <LocationJSX
          showLocation={showLocation}
          setShowLocation={setShowLocation}
          currentLocationAddress={currentLocationAddress}
          allLocations={allLocations}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          dialogType={dialogType}
          setSidebar={setSidebar}
          route={route}
        />
      </div>
      <div
        className={classNames(
          "bg-white fixed top-[57.6px] right-0 h-full w-full transition-transform duration-500 flex flex-col pl-4",
          showSubsection?.id !== null ? "translate-x-0" : "translate-x-full"
        )}
      >
        {/* header */}
        <div className="py-4 flex gap-3 items-center text-s">
          <img
            onClick={() => {
              // Updating ID immediately for the back to work properly
              setShowSubsection((showSubsection) => ({
                ...showSubsection,
                id: null,
              }));

              // Delaying the removal of data for UI
              setTimeout(() => {
                setShowSubsection((showSubsection) => ({
                  ...showSubsection,
                  data: null,
                }));
              }, 300);
            }}
            className="h-6 cursor-pointer"
            src="/assets/images/icons/arrow.svg"
            alt="left-arrow"
          />

          {showSubsection.title}
        </div>
        {/* content */}
        <div
          className={classNames(
            "pt-4 flex flex-col overflow-y-scroll hide-scrollbar",
            showSubsection.title !== "Appointments"
              ? dialogType.header
                ? dialogType.holidayHeader
                  ? "pb-32"
                  : "pb-24"
                : "pb-[57.6px]"
              : ""
          )}
        >
          {showSubsection.data
            ?.filter((subData) => subData.mobileOnly !== false)
            ?.map((subData) =>
              subData.subHeading.length === 0 ? (
                <div>
                  <div
                    className={classNames(
                      "text-s flex font-serif",
                      subData.subSection === 1
                        ? "flex-col"
                        : "flex gap-4 w-full overflow-x-scroll hide-scrollbar" +
                            (showSubsection.title !== "Appointments" &&
                            showSubsection.title !== "About"
                              ? " border-t border-light-blue"
                              : "")
                    )}
                  >
                    {subData.links.map((link) =>
                      link.overlay ? (
                        <div className="mt-4 mb-5 last:mr-4">
                          <Link
                            href={getUrl(link.link)}
                            onClick={() => setSidebar(false)}
                          >
                            <div
                              className={imgAspect("", showSubsection.title)}
                            >
                              <img
                                src={link.overlay}
                                className="object-cover w-full h-full"
                                alt="overlay"
                              />
                              <p className="mt-[10px] text-xs font-sans">
                                {link.title}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <Link
                          key={link}
                          href={getUrl(link.link)}
                          onClick={() => setSidebar(false)}
                          className="mb-7 text-xs"
                        >
                          {link.title}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div className="border-t border-light-blue mr-4">
                  <Accordian
                    title={subData.subHeading}
                    content={showSubsection.data
                      .filter(({ subHeading }) => subHeading.length > 0)
                      .filter(
                        ({ subHeading }) => subHeading === subData.subHeading
                      )}
                    getUrl={getUrl}
                    setResetSearch={setResetSearch}
                    setSidebar={setSidebar}
                  />
                </div>
              )
            )}
        </div>
        {showSubsection.title === "Appointments" ? (
          <div className="pt-4 pr-4">
            <Button
              onClick={() => setSidebar(false)}
              variant="custom"
              className={
                "h-8 border border-theme-blue text-theme-blue hover:text-white hover:bg-light-blue hover:border-none text-xs"
              }
            >
              <Link href={getUrl(`/consultation/?location=${currentLocation}`)}>
                Book an Appointment
              </Link>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Header = ({
  navData,
  cartItems,
  showToastMessage,
  onCartClick,
  onSearchClick,
  faveTotalItems,
}) => {
  const router = useRouter();
  const { dialogType } = useDialog();
  const [showToast, setShowToast] = useState(showToastMessage ?? true);
  const [showSidebar, setSidebar] = useState(false);
  const { setResetSearch } = useContext(AppContext);
  const [rakutenQuery, setRakutenQuery] = useState({
    queryExist: false,
    query: {},
  });
  const [showPrimaryNav, setShowPrimaryNav] = useState(true);
  const { totalCompareItems } = useCompare();
  const width = typeof window !== "undefined" && window.innerWidth;

  const [showCurrentLocation, setShowCurrentLocation] = useState(false);

  const [currentLocation, setCurrentLocation] = useState(() =>
    getInitialLocation(router)
  );
  const [showSubMenu, setShowSubMenu] = useState({ showIdx: null });
  const [showAllLocations, setShowAllLocations] = useState(false);
  const currentLocationAddress = find(
    locationArr,
    ({ location }) => currentLocation === location
  );
  const { showCart } = useCart();

  useEffect(() => {
    if (showSubMenu?.showIdx !== null) {
      setShowSubMenu({ subIdx: null });
      if (width < 1024) {
        setSidebar(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  /**
   * Handles the scroll event to determine whether to show or hide the secondary navigation.
   * @function handleScroll
   * @global
   * @return {void}
   */
  function handleScroll() {
    if (typeof window !== "undefined" && window.innerWidth > 1024) {
      // Apply only on desktop
      if (window.scrollY > 60) {
        setShowPrimaryNav(false);
      } else {
        setShowPrimaryNav(true);
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const reamazeWidget = document.getElementById("reamaze-widget");
    const isAnyModalOpen =
      showSidebar ||
      showCurrentLocation ||
      showSubMenu.showIdx !== null ||
      showCart.value;

    // Update the z-index for the reamaze widget
    if (reamazeWidget) {
      reamazeWidget.style.zIndex = isAnyModalOpen ? "0" : "2147483646";
    }
    document.body.classList.toggle("body--modal-open", isAnyModalOpen);
  }, [showSidebar, showCurrentLocation, showSubMenu.showIdx, showCart.value]);

  useEffect(() => {
    const { ranMID } = router.query;
    if (dialogType.bottom && ranMID) {
      setRakutenQuery({
        queryExist: true,
        query: getRakutenParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, dialogType.bottom]);

  const getUrl = (url = "") => {
    const queryExist = !isEmpty(queryString.parseUrl(url)?.query || "");
    return `${url}${
      rakutenQuery.queryExist
        ? queryExist
          ? `&${queryString.stringify(rakutenQuery.query)}`
          : `?${queryString.stringify(rakutenQuery.query)}`
        : ""
    }`;
  };

  // const closeToast = () => {
  //   setShowToast(false);
  //   setDialogType({ ...dialogType, header: false, holidayHeader: false });
  // };

  const route = router.asPath;

  const bannerText = {
    top:
      width < 768
        ? "Shop the Gift Guide—Perfect Picks Guaranteed to Arrive by Christmas ✨"
        : "Shop the Gift Guide—Perfect Picks Guaranteed to Arrive by Christmas ✨",
    bottom:
      width < 768
        ? "10% OFF LAB DIAMONDS THROUGH SEP 8TH. CODE: SUNSET2024. 🍂✨"
        : "SHOP THE END OF SUMMER SALE. 10% OFF LAB DIAMONDS THROUGH SEP 8TH. CODE: SUNSET2024. 🍂✨",
  };

  return (
    <nav className="w-full relative text-theme-blue bg-white">
      {/* mobile sidebar overlay */}
      <div
        onClick={() => setSidebar(false)}
        className={classNames(
          "bg-black h-screen w-full fixed z-10 lg:hidden",
          getTopOffset(route, dialogType, navData),
          showSidebar ? "fade-enter-active-overlay" : "fade-enter"
        )}
      ></div>
      {/* toast */}
      <div
        className={`max-h-0 bg-theme-blue text-white top-0 right-0 left-0 ${
          showToast && dialogType.header
            ? `${dialogType?.holidayHeader ? "h-16" : "h-10"} max-h-16`
            : "max-h-0"
        } overflow-hidden`}
      >
        <div className="flex items-center justify-between h-full">
          <div
            className={`w-full ${
              dialogType?.holidayHeader
                ? "flex flex-col items-center justify-center"
                : "m-auto"
            }`}
          >
            <Link
              legacyBehavior
              href={`/gift-guide/${
                rakutenQuery.queryExist
                  ? `?${queryString.stringify(rakutenQuery.query)}`
                  : ""
              }`}
            >
              <a
                target="_blank"
                rel="noreferrer"
                // id="header_menu_calendly_banner"
                id="summer_sale_banner"
                className={classNames(
                  "text-sm text-center block flex-1 focus:outline-none uppercase",
                  // adding arbitrary values so that it doesn't break into two lines
                  width < 500 ? "text-[10px]" : ""
                )}
              >
                {/* <span className="border-b">BOOK A CONSULTATION</span>{" "} */}
                {bannerText.top}
              </a>
            </Link>
            {dialogType?.holidayHeader && (
              <Link
                id="summer_sale_banner"
                legacyBehavior
                href={`/diamonds/lab-grown-diamonds/${
                  rakutenQuery.queryExist
                    ? `?show_grid=true&page=1&limit=15&${queryString.stringify(
                        rakutenQuery.query
                      )}`
                    : "?show_grid=true&page=1&limit=15"
                }`}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="header_menu_holiday_banner"
                  className={classNames(
                    "text-sm text-center block flex-1 focus:outline-none uppercase",
                    // adding arbitrary values so that it doesn't break into two lines
                    width < 500 ? "text-[10px]" : ""
                  )}
                >
                  {dialogType?.holidayHeader && (
                    <p className="md:mt-0.5">{bannerText.bottom}</p>
                  )}
                </a>
              </Link>
            )}
          </div>

          {/* <button
            className="w-6 h-6 text-white mr-1 md:mr-2"
            onClick={() => closeToast()}
          >
            <CloseIcon />
          </button> */}
        </div>
      </div>
      {/* top navbar */}
      <div
        className={classNames(
          "w-full h-14 px-4 lg:px-6 flex justify-between items-center text-s border-theme-blue border-b lg:border-none transition-all duration-300 ease-in-out",
          showPrimaryNav
            ? "max-h-14 opacity-100 translate-y-0"
            : "max-h-0 opacity-0 -translate-y-4"
        )}
      >
        {/* location layout*/}
        <div className="hidden lg:flex gap-4">
          {/* location */}

          <div
            className="flex gap-3 cursor-pointer"
            onClick={() => {
              setShowCurrentLocation(
                (showCurrentLocation) => !showCurrentLocation
              );
            }}
          >
            <HeaderIcons.LocationIcon className="w-[18px] h-[18px]" />
            <p>
              {currentLocation}
              <span className="inline-block ml-3">
                <img
                  className={classNames(
                    "w-2.5 transition-all duration-300",
                    showCurrentLocation ? "rotate-180" : "rotate-90"
                  )}
                  src="/assets/images/icons/chevron.svg"
                  alt="chevron"
                />
              </span>
            </p>
          </div>
          {/* phone number */}
          <div className="flex gap-3">
            <HeaderIcons.PhoneIcon className="w-[18px] h-[18px]" />
            <a
              href={`tel:${
                find(
                  locationArr,
                  ({ location }) => currentLocation === location
                )?.phone
              }`}
            >
              {
                find(
                  locationArr,
                  ({ location }) => currentLocation === location
                )?.phone
              }
            </a>
          </div>
        </div>
        <div className="flex items-center lg:hidden gap-2.5">
          <img
            onClick={() => setSidebar(true)}
            id="header_menu_hamburger"
            className="h-3 w-5 cursor-pointer"
            src="/assets/images/header/hamburger.svg"
            alt="hamburger"
          />
          <div
            onClick={(e) => {
              e.preventDefault();
              // toast is causing UI shift, disable for search
              setShowToast(true);
              onSearchClick();
            }}
          >
            <HeaderIcons.Search className="h-5 w-5 text-theme-blue" />
          </div>
        </div>
        {/* logo */}
        <Link
          href={`/`}
          id="header_menu_logo"
          className="absolute left-1/2 transform -translate-x-1/2 "
        >
          <img
            src={`/assets/images/header/${
              typeof window !== "undefined" && window.innerWidth < 1024
                ? "mobile-logo"
                : "logo"
            }.svg`}
            className="h-8 lg:h-7"
            alt="Frank Darling"
          />
        </Link>
        <div className="block lg:hidden">
          <div className="flex gap-2.5">
            <Link
              href={`/wishlist`}
              id="header_menu_wishlist"
              className="relative"
            >
              {faveTotalItems > 0 ? (
                <HeaderIcons.WishlistHeart className="w-5 h-5" />
              ) : (
                <HeaderIcons.Heart className="w-5 h-5" />
              )}
              {faveTotalItems > 0 ? (
                <span className="absolute -right-1 sm:right-0 top-1 w-3 h-3 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                  {faveTotalItems}
                </span>
              ) : null}
            </Link>
            <Link
              href=""
              id="header_menu_cart"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onCartClick();
              }}
              className="relative"
            >
              {cartItems > 0 ? (
                <HeaderIcons.ActiveCart className="w-5 h-5" />
              ) : (
                <HeaderIcons.Cart className="w-5 h-5" />
              )}
              {cartItems > 0 ? (
                <span className="absolute -right-1 sm:right-0 top-1 w-3 h-3 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                  {cartItems}
                </span>
              ) : null}
            </Link>
          </div>
        </div>
        {/* appointment */}
        <div className="hidden lg:flex gap-3">
          <HeaderIcons.AppointmentIcon className="w-[18px] h-[18px]" />
          <Link href={`/consultation`}>Book an Appointment</Link>
        </div>
      </div>
      {/* location dropdown */}
      <div
        className={classNames(
          "hidden lg:block absolute bg-white left-0 w-[346px] lg:pb-5 lg:px-5 h-0 border-theme-blue border-r border-b border-t overflow-hidden",
          showCurrentLocation
            ? "fade-enter-active pointer-events-auto"
            : "fade-enter pointer-events-none", // The visibility will be delayed by 1 second (matching the opacity transition duration)
          showAllLocations ? "lg:h-[275px]" : "lg:h-48",
          showSubMenu.showIdx === null ? "z-20" : "z-0"
        )}
      >
        {/* header */}
        <div className="h-12 bg-white text-s flex items-center justify-between mb-5 border-b border-light-blue">
          <p className="text-xs">
            {showAllLocations ? (
              <div className="flex items-center gap-2">
                <img
                  onClick={() => setShowAllLocations(false)}
                  className="h-5 rotate-180 cursor-pointer"
                  src="/assets/images/icons/arrow.svg"
                  alt="left-arrow"
                />
                All Locations
              </div>
            ) : (
              currentLocation
            )}
          </p>
          <div onClick={() => setShowCurrentLocation(false)}>
            <CloseIcon className={`h-3 w-3 cursor-pointer`} />
          </div>
        </div>
        {/* content */}
        {/* current location */}
        <div
          className={classNames(
            showAllLocations ? "fade-enter" : "fade-enter-active"
          )}
        >
          <div
            className={classNames("w-full text-xs mb-7 flex justify-between")}
          >
            <div>
              <p>{currentLocationAddress.address}</p>
              <p>{currentLocationAddress.city}</p>
              <a href={`tel:${currentLocationAddress.phone}`}>
                {currentLocationAddress.phone}
              </a>
            </div>
            <p
              className="text-xs text-light-blue hover:text-theme-blue hover:underline transition-all duration-300 cursor-pointer"
              onClick={() => setShowAllLocations(true)}
            >
              View All
            </p>
          </div>
          <Link
            href={`/consultation/?location=${currentLocation}`}
            className="text-xs text-light-blue hover:text-theme-blue hover:underline transition-all duration-300"
          >
            Book An Appointment
          </Link>
        </div>
        {/* all locations */}
        <div
          className={classNames(
            "text-xs my-3 absolute top-14",
            !showAllLocations ? "fade-enter" : "fade-enter-active"
          )}
        >
          {locationArr.map((location) => (
            <p
              key={location}
              className="mb-[12px] last:mb-0 cursor-pointer"
              onClick={() => {
                setCurrentLocation(location.location);
                persistCurrentLocation(location.location);
              }}
            >
              <div className="flex items-center gap-2">
                <div className="h-[10px] w-[10px] rounded-full border border-theme-blue flex items-center justify-center">
                  {currentLocation === location.location ? (
                    <div className="h-[6px] w-[6px] rounded-full bg-theme-blue"></div>
                  ) : null}
                </div>
                <p>{location.location}</p>
              </div>
            </p>
          ))}
        </div>
      </div>
      {/* location overlay */}
      <div
        onClick={() => setShowCurrentLocation(false)}
        className={classNames(
          "hidden lg:block fixed h-screen w-full bg-black z-10",
          showCurrentLocation ? "fade-enter-active-overlay" : "fade-enter"
        )}
      ></div>
      <MobileNav
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        navData={navData}
        getUrl={getUrl}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        currentLocationAddress={currentLocationAddress}
        allLocations={locationArr}
        dialogType={dialogType}
        setResetSearch={setResetSearch}
        route={route}
      />

      {/* bottom navbar desktop*/}
      <div
        onMouseLeave={() => setShowSubMenu({ showIdx: null })}
        className={classNames(
          "w-full h-12 bg-white px-6 text-s hidden lg:flex justify-between items-center border-b border-theme-blue",
          !showPrimaryNav ? "sticky" : "relative"
        )}
      >
        {/* search icon */}
        <div className="">
          <a
            className="flex items-center gap-3 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              // toast is causing UI shift, disable for search
              // setShowToast(true);
              onSearchClick();
            }}
          >
            <HeaderIcons.Search className="h-[18px] w-[18px]" />
            <span>Search</span>
          </a>
        </div>
        {/* product items */}
        <div className="">
          <ul className="list-none flex gap-6">
            {navData.map((item, idx) =>
              item.subSections ? (
                <li
                  key={idx}
                  // onMouseLeave={() => setShowSubMenu({ showIdx: null })}
                >
                  <span
                    onMouseEnter={() => setShowSubMenu({ showIdx: idx })}
                    className={classNames(
                      "cursor-pointer",
                      showSubMenu.showIdx === idx ? "underline" : ""
                    )}
                  >
                    {item.heading}
                  </span>
                  {item.subSections ? (
                    <div
                      className={classNames(
                        "absolute top-12 w-full left-0",
                        showSubMenu.showIdx === idx
                          ? "fade-enter-active"
                          : "fade-enter",
                        showSubMenu.showIdx !== null ? "z-30" : "z-0"
                      )}
                      // onMouseLeave={() => setShowSubMenu({ showIdx: null })}
                    >
                      <SubmenuJSX
                        heading={item.heading}
                        subSections={item.subSections.filter(
                          (subData) => subData.mobileOnly !== true
                        )}
                        getUrl={getUrl}
                        setResetSearch={setResetSearch}
                        closeHandler={(id) => setShowSubMenu({ showIdx: id })}
                      />
                      {/* overlay */}

                      <div
                        className={classNames(
                          "bg-black h-screen",
                          showSubMenu.showIdx === idx
                            ? "fade-enter-active-overlay"
                            : "fade-enter"
                        )}
                        onMouseEnter={() => setShowSubMenu({ showIdx: null })}
                      ></div>
                    </div>
                  ) : null}
                </li>
              ) : (
                <Link
                  key={idx}
                  href={getUrl(item.link)}
                  onMouseEnter={() => setShowSubMenu({ showIdx: null })}
                >
                  {!item.mobileOnly ? item.heading : null}
                </Link>
              )
            )}
          </ul>
        </div>
        {/* action items */}
        <div className="flex gap-4">
          <Link
            href={`/wishlist`}
            id="header_menu_wishlist"
            className="relative"
          >
            {faveTotalItems > 0 ? (
              <HeaderIcons.WishlistHeart className="w-[22px] h-[22px]" />
            ) : (
              <HeaderIcons.Heart className="w-[22px] h-[22px]" />
            )}
            {faveTotalItems > 0 ? (
              <span className="absolute -right-0.5 top-1 w-2.5 h-2.5 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                {faveTotalItems}
              </span>
            ) : null}
          </Link>
          <Link
            href={`/comparison`}
            id="header_menu_compare"
            className="relative"
          >
            {totalCompareItems > 0 ? (
              <HeaderIcons.CompareDiamond className="w-[22px] h-[22px]" />
            ) : (
              <HeaderIcons.CompareIcon className="w-[22px] h-[22px]" />
            )}
            {totalCompareItems > 0 ? (
              <span className="absolute -right-0.5 top-1 w-2.5 h-2.5 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                {totalCompareItems}
              </span>
            ) : null}
          </Link>
          <Link
            href=""
            id="header_menu_cart"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCartClick();
            }}
            className="relative"
          >
            {cartItems > 0 ? (
              <HeaderIcons.ActiveCart className="w-[22px] h-[22px]" />
            ) : (
              <HeaderIcons.Cart className="w-[22px] h-[22px]" />
            )}
            {cartItems > 0 ? (
              <span className="absolute -right-0.5 top-1 w-2.5 h-2.5 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                {cartItems}
              </span>
            ) : null}
          </Link>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  /**
   * Header main links
   */
  navData: PropTypes.array,
  /*
   * Number of items added to the cart
   */
  cartItems: PropTypes.number,
  /*
   * Number of items added to the fave
   */
  faveTotalItems: PropTypes.number,
  /**
   * Is to show toast message
   */
  showToastMessage: PropTypes.bool,
  /**
   * Function for cart icon click
   */
  onCartClick: PropTypes.func,
};

Header.defaultProps = {
  navData: [
    {
      heading: "About",
      subSections: [
        {
          subHeading: "About",
          links: [
            {
              title: "Our Story",
              link: "/about",
            },
            {
              title: "Ethical Sourcing",
              link: "/etiquette",
            },
          ],
        },
      ],
    },
    {
      heading: "Etiquette",
      link: "/etiquette",
    },
    {
      heading: "Diamond Comparison",
      link: "/comparison/",
      mobileOnly: true,
    },
    {
      heading: "Help",
      link: "/contact",
    },
  ],
  cartItems: 0,
};

export default Header;
